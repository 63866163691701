<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-lg">
			<div class="ng-block-head-sub d-print-none">
				<router-link class="back-to" to="/">
					<em class="icon ni ni-chevron-left-c mr-1"></em>
					<span>Dashboard</span>
				</router-link>
			</div>
			<div class="ng-block-between-md g-4">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">
						{{ $route.meta.title }}
					</h2>
					<div class="ng-block-des d-print-none">
						<p>Recent Pricing Calculations</p>
					</div>
				</div>
				<div class="ng-block-head-content">
					<ul class="ng-block-tools gx-3 d-print-none">
						<li>
							<div class="form-group">
								<input
									type="text"
									class="form-control"
									placeholder="Type to search..."
									v-model="search"
									@keyup="searchRecords"
								/>
							</div>
						</li>
						<li>
							<router-link
								to="/calculate"
								data-toggle="modal"
								class="btn btn-white btn-dim btn-outline-primary"
							>
								<em class="icon ni ni-plus-c"></em>
								<span>
									New
									<span class="d-none d-sm-inline-block">
										Calculation
									</span>
								</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="ng-block">
			<div class="card card-bordered loading" v-if="processing">
				<div class="justify-content-center text-center">
					<div class="spinner-grow text-primary m-3" role="status">
						<span class="sr-only">Processing Data...</span>
					</div>
					<h6 class="mb-4">Processing Data...</h6>
				</div>
			</div>

			<div class="card card-bordered" v-else>
				<data-table
					:headers="headers"
					:records="records.data"
					@delete="deleteRecord"
				>
					<template #title="scope">
						{{ scope.record?.product?.name || "View Calculation" }}
					</template>

					<template #details="scope">
						<div class="card card-bordered">
							<div class="card-content">
								<table class="table pmd-table table-orders">
									<thead class="tb-odr-head">
										<tr class="tb-odr-item">
											<th></th>

											<!-- <th
											class="text-right"
											v-if="
												scope.record.user?.contact
													?.currency == 'euro'
											"
										>
											Price(€)
										</th> -->
											<th class="text-center">
												Price(KSh)
											</th>
											<th class="text-center">
												Price(£)
											</th>
											<!-- <th
											class="text-right"
											v-else-if="
												scope.record.user?.contact
													?.currency == 'dollar'
											"
										>
											Price($)
										</th> -->
										</tr>
									</thead>

									<tbody
										class="tb-odr-body"
										v-if="settings.forex"
									>
										<tr class="tb-odr-item">
											<th>Per Kg</th>
											<td
												class="text-center font-weight-light font-size-20 m-b-0"
											>
												{{
													(scope.record.details?.kgs).toFixed(
														2
													)
												}}
											</td>
											<td
												class="text-center font-weight-light font-size-20 m-b-0"
											>
												{{
													(
														scope.record.details
															?.kgs /
														(settings?.forex?.rate[
															"pound"
														] || 1)
													).toFixed(2)
												}}
											</td>
										</tr>
										<tr>
											<th>Per Box</th>
											<td
												class="text-center font-weight-light font-size-20 m-b-0"
											>
												{{
													(
														scope.record.details
															?.kgs *
														scope.record.details
															?.box_weight
													).toFixed(2)
												}}
											</td>
											<td
												class="text-center font-weight-light font-size-20 m-b-0"
											>
												{{
													(
														(scope.record.details
															?.kgs *
															scope.record.details
																?.box_weight) /
														(settings?.forex?.rate[
															"pound"
														] || 1)
													).toFixed(2)
												}}
											</td>
										</tr>
										<tr>
											<td colspan="3">
												<button
													@click.prevent="
														viewCalculation(
															scope.record
														)
													"
													class="btn btn-primary float-right"
												>
													Full Details
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</template>

					<template #form="scope">
						<form
							class="form row"
							@submit.prevent="updateRecord(scope.record)"
						>
							<div class="form-group col-md-6">
								<label>
									Select Product
								</label>
								<Multiselect
									:options="products"
									v-model="scope.record.product_id"
									trackBy="name"
									label="name"
									valueProp="id"
									:searchable="true"
									placeholder="Select Item"
								/>
							</div>
							<div class="form-group col-md-6">
								<label>Target</label>
								<input
									class="form-control"
									v-model="scope.record.target"
								/>
							</div>
							<div class="form-group col-md-6">
								<label>Loose</label>
								<input
									class="form-control"
									v-model="scope.record.loose"
								/>
							</div>
							<div class="form-group col-md-6">
								<label>Pre-Pack</label>
								<input
									class="form-control"
									v-model="scope.record.prepack"
								/>
							</div>
							<div class="form-group col-md-12">
								<button class="btn btn-primary float-right">
									<em class="icon ni ni-save"></em>
									<span>
										Update {{ scope.record.name }}
									</span>
								</button>
							</div>
						</form>
					</template>
				</data-table>
			</div>

			<paginate
				:data="records"
				@changed="fetchRecords"
				v-if="records.data.length"
			/>
		</div>
	</div>
</template>

<script lang="ts">
	import http from "../../../../common/services/http";
	import { defineComponent, ref, reactive, onMounted, computed } from "vue";
	import router from "../router";

	export default defineComponent({
		setup(props) {
			const headers = {
				user: "Customer",
				product: "Product",
				kg: "Per Kg",
				box: "Per Box",
			};

			const settings = ref({});

			const records = ref({
				data: [],
			});

			const search = ref("");

			const processing = ref(false);
			const updated = ref(false);
			const products = ref([]);

			function fetchproducts() {
				processing.value = true;

				http.get(`/api/products?fetch=all`).then((res) => {
					products.value = res.data;
					processing.value = false;
				});
			}

			function fetchRecords(page = 1) {
				processing.value = true;

				http.get(`/api/calculations?page=${page}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function searchRecords(e: any) {
				processing.value = true;

				http.get(`/api/calculations?s=${search.value}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function updateRecord(record: any) {
				record._method = "PUT";
				http.post(`/api/calculations/${record.id}`, record)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchRecords();
					});
			}

			function deleteRecord(id: any) {
				http.post(`/api/calculations/${id}`, {
					_method: "DELETE",
				}).then((res) => {
					fetchRecords();
				});
			}

			function viewCalculation(data: any) {
				document.getElementById("hideView")?.click();
				router.replace(`/calculations/${data.id}`);
			}

			onMounted(() => {
				fetchRecords();
				fetchproducts();

				http.get(`/api/settings`).then((res: any) => {
					settings.value = res.data;
				});
			});

			return {
				headers,
				fetchRecords,
				processing,
				records,
				updateRecord,
				updated,
				deleteRecord,
				search,
				searchRecords,
				products,
				viewCalculation,
				settings,
			};
		},
	});
</script>
