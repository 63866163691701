
	import http from "../../../../common/services/http";
	import { defineComponent, ref, reactive, onMounted, computed } from "vue";
	import router from "../router";

	export default defineComponent({
		setup(props) {
			const headers = {
				user: "Customer",
				product: "Product",
				kg: "Per Kg",
				box: "Per Box",
			};

			const settings = ref({});

			const records = ref({
				data: [],
			});

			const search = ref("");

			const processing = ref(false);
			const updated = ref(false);
			const products = ref([]);

			function fetchproducts() {
				processing.value = true;

				http.get(`/api/products?fetch=all`).then((res) => {
					products.value = res.data;
					processing.value = false;
				});
			}

			function fetchRecords(page = 1) {
				processing.value = true;

				http.get(`/api/calculations?page=${page}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function searchRecords(e: any) {
				processing.value = true;

				http.get(`/api/calculations?s=${search.value}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function updateRecord(record: any) {
				record._method = "PUT";
				http.post(`/api/calculations/${record.id}`, record)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchRecords();
					});
			}

			function deleteRecord(id: any) {
				http.post(`/api/calculations/${id}`, {
					_method: "DELETE",
				}).then((res) => {
					fetchRecords();
				});
			}

			function viewCalculation(data: any) {
				document.getElementById("hideView")?.click();
				router.replace(`/calculations/${data.id}`);
			}

			onMounted(() => {
				fetchRecords();
				fetchproducts();

				http.get(`/api/settings`).then((res: any) => {
					settings.value = res.data;
				});
			});

			return {
				headers,
				fetchRecords,
				processing,
				records,
				updateRecord,
				updated,
				deleteRecord,
				search,
				searchRecords,
				products,
				viewCalculation,
				settings,
			};
		},
	});
